import {
  IArticleData,
  IAuthorBoxData,
  IWebinarSpeakersArticleProps
} from '../models/author-with-article-model';

export interface ISpeakerData {
  authorData: IAuthorBoxData;
  articles: IArticleData[];
}

export const speakersDataResolver = (
  data: IWebinarSpeakersArticleProps[]
): ISpeakerData[] => {
  const speakers = data
    .map(current =>
      current.speakers.map(
        speaker =>
          speaker.slug !== 'tenderhutdigital' && {
            authorData: speaker,
            articles: getArticlesByAuthor(speaker, data)
          }
      )
    )
    .flat()
    .filter(
      (value, index, self) =>
        index === self.findIndex(t => t.authorData.id === value.authorData.id)
    );

  return speakers;
};

const getArticlesByAuthor = (
  author: IAuthorBoxData,
  data: IWebinarSpeakersArticleProps[]
): IArticleData[] => {
  const articles: IArticleData[] = [];

  data.map(obj =>
    obj.speakers.map(
      speaker => speaker.id === author.id && articles.push(obj.article)
    )
  );

  return articles;
};
