import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { pageDataResolver } from '../utils/pageDataResolver';
import { Navbar } from '../components/navbar/navbar';
import RichText from '../components/rich-text/rich-text';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { Footer } from '../components/footer/footer';
import { SeoComponent } from '../components/seo-component/seo-component';
import { PageProps } from 'gatsby';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { filterById } from '../utils/utils';
import {
  AuthorWithArticleModel,
  IAuthorWithArticleProps,
  IWebinarSpeakersArticleProps
} from '../models/author-with-article-model';
import { speakersDataResolver } from '../utils/speakersDataResolver';
import '../styles/global.scss';
import '../styles/reset.css';
import { withCookieBar } from '../hoc/withCookieBar';

interface IData {
  allKontentItemWebinar: {
    totalCount: number;
    nodes: IWebinarArticle[];
  };
  kontentItemPage: IPage;
  cookieSection: ICookieData;
}

interface IArticles {
  featured: IWebinarArticle[];
  notFeatured: IWebinarArticle[];
}

type WebinarPageProps = PageProps<IData, IPageContext>;

const WebinarPage: FunctionComponent<WebinarPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });

    const debouncedResize = debounce(handleResize, 300);

    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    handleChangePageState();
    handleResize();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.location.state,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice
  ]);

  const filteredArticles = useMemo(
    () => filterById(props.data.allKontentItemWebinar.nodes),
    [props.data.allKontentItemWebinar.nodes]
  );
  const sortedArticles = useMemo(
    () => (): IArticles => {
      return filteredArticles.reduce(
        (articles: IArticles, article: IWebinarArticle) => {
          // const featured =
          //   get(article, 'elements.featured.value[0].codename', false) ===
          //   'true';

          // if (featured) {
          //   articles.featured.push(article);
          //   return articles;
          // }

          articles.notFeatured.push(article);
          return articles;
        },
        {
          featured: [],
          notFeatured: []
        }
      );
    },
    [filteredArticles]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  const articleWithAuthorData = useMemo(
    () =>
      filteredArticles.map(
        (article: IWebinarArticle): IWebinarSpeakersArticleProps => {
          return AuthorWithArticleModel.createWebinar(article);
        }
      ),
    [filteredArticles]
  );
  const authorsData = useMemo(
    () => speakersDataResolver(articleWithAuthorData),
    [articleWithAuthorData]
  );

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <RichText
        {...props.data.kontentItemPage.elements.body}
        articles={sortedArticles()}
        authors={authorsData}
        allAuthors={true}
      />
      {pageData.footerData && <Footer {...pageData.footerData} />}
    </>
  );
};

export const Head = (props: WebinarPageProps) => {
  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return <SeoComponent {...pageData.seoData} />;
};

export default withAppStore(
  withCookieBar(WebinarPage as FunctionComponent<{}>)
);

export const query = graphql`
  query($pageId: String, $pageLanguage: String) {
    allKontentItemWebinar(
      filter: { system: { language: { eq: $pageLanguage } } }
      sort: { fields: system___last_modified, order: DESC }
    ) {
      totalCount
      nodes {
        ...FragmentWebinar
      }
    }
    kontentItemPage(system: { id: { eq: $pageId } }) {
      ...FragmentPageComponent
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
